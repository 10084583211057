<template>
  <div class="page">
    <div class="page-main">
      <div class="alert" v-if="$route.query.pushStatus == '0'">{{$t('sipAccount.tips')}}</div>
      <el-table
        class="tr-stretching-table"
        border
        :data="accountList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="accountNo" :label="$t('device.account')" width="150">
          <template slot-scope="scope">{{ $t('device.account') + scope.row.accountNo }}</template>
        </el-table-column>
        <el-table-column prop="sipUserId" :label="$t('sipAccount.userName')">
          <template slot-scope="scope">{{
            scope.row.sipUserId ? scope.row.sipUserId : '-'
          }}</template>
        </el-table-column>
        <el-table-column prop="serverName" :label="$t('sipServer.serverName')">
          <template slot-scope="scope">{{
            scope.row.serverName ? scope.row.serverName : '-'
          }}</template>
        </el-table-column>
        <el-table-column prop="sipServer" :label="$t('sipServer.sipServer')">
          <template slot-scope="scope">{{
            scope.row.sipServer ? scope.row.sipServer : '-'
          }}</template>
        </el-table-column>
        <el-table-column prop="operation" :label="$t('operation')">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="$t('device.deviceAccountAssign')"
              placement="top"
              :open-delay="1000"
            >
            <el-button
              type="text"
              class="btn-p0"
              @click="editItem(scope)"
              icon="el-icon-edit iconfont icon-edit"
            ></el-button>
          </el-tooltip>
            <el-tooltip
              effect="dark"
              :content="$t('delete')"
              placement="top"
              :open-delay="1000"
            >
            <el-button
              type="text"
              class="btn-p0"
              @click="deleteItem(scope.row.accountId)"
              icon="el-icon-delete iconfont icon-delete"
              :disabled="!scope.row.sipUserId.length"
            ></el-button>
          </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-btns" style="margin-top: 100px">
        <el-button class="footer-btn"
          type="primary"
          plain
          @click="$router.go(-1)"
        >{{$t('device.back')}}</el-button>
        <el-button class="footer-btn"
          :disabled="accountList.length ? false : true"
          type="primary"
          @click="submit"
        >{{$t('push')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { pushDeviceConfig } from '@/api/setParam'
import { getNonUniqueIds } from '@/plugins/methods'
import { accountConfigQuery, unbindDevice } from '@/api/sipAccount'
import { deviceAccountList, deviceAccountAssign } from '@/api/device'

export default {
  name: 'DeviceAccount',
  data() {
    return {
      accountList: [],
      oldAccountList: [],
      userIdList: [],
    }
  },
  computed: {
    ...mapGetters('dist', ['accountStatus']),
  },
  created() {
    this.getData(this.$route.query.id)
    this.getUserId()
  },
  methods: {
    // 账户唯一性校验
    accountUniquevalid() {
      let repeatIds = getNonUniqueIds(this.accountList, 'accountId')
      this.accountList.forEach((item) => {
        if (item.accountId && repeatIds.indexOf(item.accountId) > -1) {
          item.errText = this.$t('device.userNameRule')
        } else {
          item.errText = ''
        }
      })
      let validStatus = this.accountList.every((item) => {
        return item.errText
      })
      return validStatus
    },
    getData(id) {
      deviceAccountList({ id }).then(({ data }) => {
        this.accountList = data.result.rows
        this.oldAccountList = JSON.parse(JSON.stringify(this.accountList))
      })
    },
    getUserId() {
      accountConfigQuery({
        siteId: this.$route.query.siteId,
        deviceId: this.$route.query.id
      }).then((res) => {
        // console.log('accountConfigQuery')
        if (res.data.code == 0) {
          this.userIdList = res.data.result.rows
        }
      })
    },
    editItem(scope) {
      console.log('editItem', this.$route.query.id, scope.row.accountId)
      this.$router.push(
        { path: '/device/deviceAccountAssign' , 
          query: {
            deviceId: this.$route.query.id,
            accountId: scope.row.id,
            sipUserId: scope.row.sipUserId,
            sipServer: scope.row.serverName ? scope.row.serverName : scope.row.sipServer,
            accountNo: scope.row.accountNo,
            pushStatus: 0
          },
        }
      )
    },
    // 保存
    submit() {
      let validStatus = this.accountUniquevalid()
      if (validStatus) {
        return
      }
      let req = []
      this.accountList.forEach((e) => {
        req.push({
          accountId: e.id,
          deviceId: this.$route.query.id,
          no: e.accountNo,
        })
      })
      if (!req.length) {
        req = [
          {
            deviceId: this.$route.query.id,
          },
        ]
      }
      deviceAccountAssign(req)
        .then(({ data }) => {
          if (this.$route.query.pushStatus == 0) {
            this.$message.success(data.message)
            this.$router.go(-1)
          } else {
            this.push()
          }
        })
        .catch(() => {})
    },
    deleteItem(accountId) {
      console.log('deleteItem', this.$route.query.id, accountId)
      let data = {
              accountId,
              deviceId: this.$route.query.id
            }
            unbindDevice(data).then(() => {
              this.getData(this.$route.query.id)
              this.refresh()
            })
            .catch(() => {
              this.$router.go(-1)
            })
    },
    
    push() {
      this.$confirm(this.$t('sitParam2.tipStr'), this.$t('sitParam2.tipTitle'), {
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no'),
        type: 'success',
        center: true,
      })
        .then(() => {
          console.log("push in DeviceAccount")
          pushDeviceConfig(this.$route.query.id)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$router.go(-1)
            })
            .catch(() => {})
        })
        .catch(() => {
          this.$router.go(-1)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page-main {
  padding: 20px 140px 20px;
}
.alert {
  height: 54px;
  background: #FFF7DB;
  border: 1px solid #F2B136;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  color: #F5A75A;
  text-align: center;
  line-height: 54px;
}
.tr-stretching-table {
  margin-top: 30px;
}
.account-list {
  min-width: 300px;
  .icon {
    margin-right: 5px;
  }
  .right {
    float: right;
  }
}
</style>
